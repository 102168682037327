@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* #root > div > .makeStyles-root-3{
  max-width: 1440px !important;
  margin-left: auto;
  margin-right: auto;
} */
.breadcrumb{
  display:flex ;
  align-items: flex-end;
  margin:0.5rem;
  /* padding: 0.3rem; */
  cursor: pointer;
}
.breadcrumb>div{
  margin:0.1rem;
  color: #2B2865;
  font-weight: 500;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.centerHeading {
  text-align: center;
  /* border: 1px solid rgb(141, 137, 137); */
  border-radius: 10px;
  color: white;
  /* background: linear-gradient(45deg, #22A4DC 30%, #2B2663 90%);  */
  /* background-color:linear-gradient(45deg, #2196F3 30%, #21CBF3 90%); */
  box-shadow: 0 3px 5px 2px rgba(33, 203, 243, 0.3);
  padding: 0.1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  word-spacing: 20px;
  font-size: larger;
  background: linear-gradient(-45deg, #19cbfc, #2b2663, #23a6d5, #f8f112);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.centerHeading>h1{
  font-weight: bolder;
}
.textSize {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  /* margin: 2rem 0rem 2rem 0rems */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #f2f2f2;
  margin-top: 2.5rem;
}
.card-container > div {
  margin: 10px;
  /* border: 1px solid; */
  /* padding: 5px; */
  box-shadow: 3px 3px 3px 3px #1d1c1c;
  border-radius: 5px;
  width: 200px;
}
.card-container > div:hover {
  background-color: rgb(17, 17, 17);
  transition: 0.3s;
}

.MuiCardMedia-img {
  /* width: 50px; */
  height: 190px;
  /* object-fit: scale-down; */
  /* margin-top: 2rem; */
  padding: 15px;
}
/* .MuiCardMedia-img:hover{
  background-color: rgb(170, 206, 204);
  transition: 0.3s;
} */
/* .drawer{
  background-color: rgba(0, 0, 0, 0.6);
} */

.backgroundImage {
  background-repeat: no-repeat;
  position: fixed;
  background-size: cover;
  width: 92vw;
  height: 85vh;
}

.chart1BMAIN {
  display: flex;
  justify-content: space-around;
}

.Card {
  width: 200px;
  height: 100px;
  border: 1px grey solid;
  /* padding: 3px; */
  box-shadow: 5px 5px 5px 5px #777676;
  border-radius: 10px;
}
/* /CHARTS STYLING/ */
.custom-tooltip {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #c3c3c3;
  border-radius: 10px;
}
.custom-tooltip > span {
  font-weight: bold;
}

.Charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.TimeSelect {
  display: block;
  margin: 50px;
  padding: 50px;
}
.TimeSelect > div {
  margin-top: 10px;
}
/* Chart1b Guage */
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.head {
  margin-left: 65px;
}
.container > div {
  /* height: 200px;
    width: 280px; */
  padding: 5px;
}
.Cards-1b {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
}
.Cards-1b > div {
  /* margin: 2px; */
  padding: 2px;
  /* border: 3px solid #2E3B55;   */
}
.guageGraph {
  /* display: flex;
    flex-direction: row;
    justify-content: space-between;
  
    align-items: center; */
  /* flex-wrap: wrap; */
}
.LossChart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.MainContainer {
  /* display: flex; */
  /* flex-direction: row; */
}

/* DEFECTS HANDLING/ */
.SelectPlant {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* align-items: center; */
}
.SelectPlant > div {
  margin-left: 5px;
  padding-left: 15px;
  /* justify-content: space-around; */
}
.dhForm {
  display: flex;
  justify-content: space-between;
  /* background: linear-gradient(to right, #606c88, #3f4c6b); */
  /* align-items: center; */

  /* flex-wrap: wrap;

align-items: center;
justify-content: space-between; */
}
.dhForm > div {
  padding: 1rem;
  /* margin: 3rem; */
  /* margin-right: 5rem; */
  /* margin-top: 5px;
  padding: 3px; */
  /* column-count: 2;
  column-gap: 5rem;
  width: 50%; */
}
.MuiDialogContent-root {
  /* overflow-y: hidden !important; */
}
.dhForm .div1t6 {
  margin-top: 1px;
}

.dhForm .div7t12 {
  margin-top: 1px;
}
.div1t6 > div {
  margin-top: 5px;
}
.div7t12 > div {
  margin-top: 5 px;
}
.DHContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.DHContainer > div {
  /* margin-top: 10px; */
}
.OHContainer {
  /* display: flex; */
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.OHContainer > div {
  margin-top: 20px;
}

/* DHGRAPH */
.DHGCards {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-wrap: wrap;
}
.DHGCards > div {
  width: 10rem;
  padding: 5px;
}
.graphContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.graphContainer > div {
  margin: 1px;
}
.graphContainer > div:hover {
  background-color: thistle;
  transition: 2s;
}

.textfield_label {
  color: #27d5e6 !important;
}
.textfield_label:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1) !important;
  padding: 3px 0px 3px 3px !important;
  margin: 5px 1px 3px 0px !important;
  border: 1px solid rgba(81, 203, 238, 1) !important;
}
.textfield_insidetext {
  color: white !important;
}

@media only screen and (max-width: 700px) {
  .Card-container > div {
    margin: 0.2rem;
  }
}

.dialog-btn {
  background: linear-gradient(45deg, #2196f3 30%, #21cbf3 90%);
  color: white !important;
  padding: 2px 2px;
  font-size: 6px;
  font-weight: bold;
}
.close-btn-abc {
  background: linear-gradient(45deg, #fe6b8b 30%, red 90%) !important;
}

@media only screen and (max-width: 700px) {
  .icon-size {
    margin-left: auto;
    margin-right: auto;
    height: auto !important;
    width: 5rem !important;
  }
  .Heading {
    margin-top: 3rem;
    text-align: center;
  }
  .Card-container > div {
    margin: 0.2rem;
  }
  .Pie-chart {
    width: 25rem !important;
    margin-left: auto;
    margin-right: auto;
  }
  .Bar-Chart > div > div {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
  }
  .centerHeading {
    margin-top: 2rem;
  }
  .DHContainer {
    margin-top: 2rem;
  }
  .Text-Gradient-Color {
    font-size: 32px !important;
  }
  .dialog-mobile-view {
    /* display: flex;
      justify-content: center;
      align-items: center; */
    font-size: 35px !important;
  }
  .typography {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px !important;
  }
  .mobile-view-form {
    margin-top: 3rem;
  }
  .mobile-view-text-field {
    height: 10vh !important;
    margin-top: 1rem;
  }
  .mobile-view-btn-container {
    display: flex;
    flex-direction: column;
  }
  .dialog-btn {
    margin-bottom: 1rem !important;
    font-size: 20px !important  ;
    padding: 4px 4px;
  }
  .close-btn-abc {
    background: linear-gradient(45deg, #fe6b8b 30%, red 90%) !important;
  }
}

@media only screen and (max-width: 764px) {
  .icon-size {
    margin-left: auto;
    margin-right: auto;
    height: auto !important;
    width: 5rem !important;
  }
}

@media only screen and (max-width: 992px) {
  .navigation-view {
    max-width: 155px !important;
  }
  /* .navigation-text{
  } */
}
@media only screen and (max-width: 350px) {
  .resp-view {
    min-width: 320px !important;
  }
  .resp-navbar {
    display: flex !important;
    flex-direction: row !important;
  }
  .resp-child-element {
    max-width: 50%;
  }
  .resp-for-mobile{
    max-width: 30% !important;
    
  }
  
}
@media only screen and (max-width: 590px) {
  .navigation-view {
    display: none !important;
  }
}

@media only screen and (max-width: 570px) {
  .navigation-view {
    display: none !important;
  }
  .resp-navbar {
    display: flex !important;
    flex-direction: row !important;
  }
  .resp-child-element {
    max-width: 50%;
    margin: 0% !important;
    padding: 0% !important;
  }
  .resp-logo-heading {
    display: none;
  }
  .resp-logo-img {
    display: block !important;
    margin-left: 0.5rem;
  }
}


@media only screen and (max-width: 430px) {
  .navigation-view {
    display: none !important;
  }
  .resp-font-size {
    font-size: 11px !important;
  }
  .main-container {
    padding: 0% !important;
  }
  .MuiIconButton-root {
    padding: 0% !important;
  }
  .drawer {
    display: none !important;
  }
  .menu-icon {
    display: none !important;
  }
  .typography {
    font-size: 24px !important;
  }
  .resp-for-mobile{
    max-width: 30% !important;
  }
  .resp-modal-for-mobile{
    width: 95% !important;
  }
  .Form-Label{
    font-size: 14px !important;
    font-weight: bold !important;
  }
  .ofsAssigned{
    display: flex !important;
    justify-content: center !important;
  }
}

.resp-logo-img {
  display: none;
}

.input {
  /* border-radius: 5px; */
  border: 1px solid grey;
}

.paper-style-web {
  border-radius: 50px;
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
  .resp-for-tab {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .resp-for-mobile {
    font-size: 65px !important;
  }
  .resp-form-container {
    width: 60% !important;
  }
  .resp-input {
    height: 55px;
    border-radius: 5px;
    border: 1px solid grey;
  }
  .Form-Label{
    font-size: 19px !important;
    font-weight: bold !important;
  }
  
}
@media only screen and (max-width: 1024px) {
 /* .SignIn-Form{
   display: flex;
 
   flex-direction: column;
 } */
 .login-paper{
  margin-top: 2rem !important;
 }
 
  .resp-for-tab {
    width: 80% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .resp-for-mobile {
    font-size: 44px !important;
  }
  .resp-form-container {
    width: 60% !important;
  }
  .resp-input {
    height: 55px;
    border-radius: 5px;
    border: 1px solid grey;
  }
}
/* 
.back-ground-color {
  background: #e0e0e0;
} */
.back-ground-color {
  background: #a1ffce; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #faffd1,
    #a1ffce
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #faffd1,
    #a1ffce
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.col-3 {
  float: left;
  width: 27.33%;
  margin: 10px 3%;
  position: relative;
}
input[type="text"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
input[type="password"] {
  font: 15px/24px "Lato", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-19,
.effect-20,
.effect-21 {
  border: 1px solid #ccc;
  padding: 7px 14px;
  transition: 0.4s;
  background: transparent;
}

.effect-19 ~ .focus-border:before,
.effect-19 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  width: 0;
  height: 2px;
  /* background-color: black; */
  border-radius: 5px;
  background-color: rgb(124,204,191);
  transition: 0.4s;
}
.effect-19 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  border-radius: 5px;

}
.effect-19 ~ .focus-border i:before,
.effect-19 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  /* background-color: #117fec; */
  border-radius: 5px;

  background-color: rgb(124,204,191);
  transition: 0.6s;
}
.effect-19 ~ .focus-border i:after {
  left: auto;
  right: 0;
}
.effect-19:focus ~ .focus-border:before,
.effect-19:focus ~ .focus-border:after,
.has-content.effect-19 ~ .focus-border:before,
.has-content.effect-19 ~ .focus-border:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
  border-radius: 5px;

}
.effect-19:focus ~ .focus-border i:before,
.effect-19:focus ~ .focus-border i:after,
.has-content.effect-19 ~ .focus-border i:before,
.has-content.effect-19 ~ .focus-border i:after {
  top: -1px;
  height: 100%;
  transition: 0.6s;
}
.effect-19 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-19:focus ~ label,
.has-content.effect-19 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  /* color: black; */
  color: rgb(124,204,191);
  transition: 0.3s;
}

.test-TwoBtn{
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse;
}


.MuiInput-underline:before{
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.42); */
  border-bottom: 1px solid #000 !important
}
